import React from 'react';

function ButtonNext({handleSubmit, buttonDisabled, buttonText, error, errorMessage}) {
  return (
    <div className='flex w-full flex-col items-center'>
        <div className='mb-2'>
            {error ? (<p className='text-xs text-[#BA891E]'>{errorMessage}</p>) : <p></p>}
        </div>   
        <button
        onClick={handleSubmit}
        disabled={buttonDisabled}
        className="py-5 w-full rounded-full text-base text-black bg-[#1db91d] duration-200 hover:bg-[#326532] disabled:bg-[#202020]">
          {buttonText}
        </button>
  </div>    
    );
}

export default ButtonNext;
